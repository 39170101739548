export const state = () => ({
  application: {
    currentUrl: '',
    routes: [
      {
        nl: 'browser-wordt-niet-ondersteund',
        fr: 'navigateur-non-supporte',
      },
      {
        nl: 'niet-geauthenticeerd',
        fr: 'non-authentifie',
      },
      {
        nl: 'geen-toegang',
        fr: 'pas-acces',
      },
    ],
    language: {
      current: 'nl-be',
      allowed: [
        {
          code: 'nl-be',
          key: 'NL',
        },
        {
          code: 'fr-be',
          key: 'FR',
        },
      ],
    },
    customization: {
      setRefererBaseAsLogoLink: false,
      enableRedirect: false,
    },
  },
  referer: {
    name: '',
    url: {
      base: null,
      redirect: null,
    },
  },
})

export const mutations = {
  setLanguage(state, language) {
    state.application.language.current = language
  },
  setCurrentUrl(state, url) {
    state.application.currentUrl = url
  },
  setReferer(state, { name, base, redirect }) {
    state.referer = {
      name,
      url: {
        base,
        redirect,
      },
    }
  },
  setApplicationCustomization(state, { replaceLogoLink, enableRedirect }) {
    state.application.customization.setRefererBaseAsLogoLink = replaceLogoLink
      ? JSON.parse(replaceLogoLink)
      : false
    state.application.customization.enableRedirect = enableRedirect
      ? JSON.parse(enableRedirect)
      : false
  },
}
