//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CDN_URL, CODEY_STYLE_VERSION } from '../variables'

export default {
  data() {
    return {
      CDN_URL,
      CODEY_STYLE_VERSION,
    }
  },
  computed: {
    replaceLogoLink() {
      return this.$store.state.application.customization
        .setRefererBaseAsLogoLink
    },
    name() {
      return this.$store.state.referer.name
    },
    logoLink() {
      if (!this.replaceLogoLink) {
        return this.$t('COMPONENTS.HEADER.XERIUS.LINK')
      }
      return this.$store.state.referer.url.base
    },
    logoAlt() {
      if (!this.replaceLogoLink) {
        return this.$t('COMPONENTS.HEADER.XERIUS.ALT')
      }
      return this.name + '.'
    },
  },
  // methods: {
  //   logoLink() {
  //     if (!this.replaceLogoLink) {
  //       return this.$t('COMPONENTS.HEADER.XERIUS.LINK')
  //     }
  //     return this.$store.state.referer.url.base
  //   },
  //   logoAlt() {
  //     if (!this.replaceLogoLink) {
  //       return this.$t('COMPONENTS.HEADER.XERIUS.ALT')
  //     }
  //     return this.name + '.'
  //   },
  // },
}
