export default ({ store }) => {
  const languageFilter = store.state.application.language.allowed
    .map((lang) => lang.code)
    .join('|')
  const regex = new RegExp(`/(${languageFilter})/`, 'i')
  const regexResult = window.location.href.match(regex)

  let language

  if (regexResult) {
    language = regexResult[1].toLowerCase()
  }

  if (language) {
    store.commit('setLanguage', language)
  }
}
