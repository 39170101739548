import { render, staticRenderFns } from "./default.vue?vue&type=template&id=a05533fa&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {XerHeader: require('/home/vsts/work/1/s/components/xer-header.vue').default,XerFooter: require('/home/vsts/work/1/s/components/xer-footer.vue').default})
