import packageJson from './package.json'

const filterVersion = (rawVersion) => {
  if (!rawVersion) {
    return
  }
  return rawVersion.replace(/[^0-9.]/g, '')
}

export const CDN_URL = 'https://cdn.xerius.be'
export const CODEY_STYLE_VERSION = filterVersion(
  packageJson.dependencies['@xerius/codey-style']
)
