//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    availableLanguages() {
      return this.$store.state.application.language.allowed.filter(
        (lang) => lang.code !== this.$store.state.application.language.current
      )
    },
  },
  methods: {
    createUrl({ code, key }) {
      let url = this.$store.state.application.currentUrl
      const currentLanguage = this.$store.state.application.language.allowed.find(
        (lang) => lang.code === this.$store.state.application.language.current
      )

      this.$store.state.application.routes.forEach((route) => {
        url = url.replace(
          route[currentLanguage.key.toLowerCase()],
          route[key.toLowerCase()]
        )
      })

      return url.replace(currentLanguage.code, code)
    },
  },
}
