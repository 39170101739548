//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  mounted() {
    this.$store.commit('setReferer', this.$route.query)
    this.$store.commit('setApplicationCustomization', this.$route.query)
  },
}
