//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CDN_URL, CODEY_STYLE_VERSION } from '../variables'

export default {
  data() {
    return {
      CDN_URL,
      CODEY_STYLE_VERSION,
    }
  },
}
